/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import PropTypes from "prop-types"

const Time = props => {
  return (
    <div
      className="time"
      css={css`
        width: 33.3333%;
      `}
    >
      <div
        className={`wrapper-year ${!props.middle ? "d-none d-md-flex" : ""}`}
        css={theme => css`
          position: relative;
          display: flex;
          justify-content: center;
          height: 100%;
          font-size: 4rem;
          font-weight: 700;
          &:before {
            content: "";
            display: block;
            position: absolute;
            top: 93px;
            left: 50%;
            margin-left: -8px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: ${theme.colors.primary};
            border: 2px solid #fff;
          }
        `}
      >
        {props.middle ? <div>{props.currentYear?.substr(0, 2)}</div> : null}

        <div
          className={`year-left ${
            props.transition ? `active-${props.direction}` : ""
          }`}
          css={theme => css`
            display: flex;
            justify-content: flex-start;
            position: relative;
            min-width: 70px;
            color: ${theme.colors.secondary};
            overflow: hidden;
          `}
        >
          {props.prevYear ? (
            <div
              className="prev-year"
              css={css`
                position: absolute;
                transform: translate3d(-100%, 0, 0);
                opacity: 0;
              `}
            >
              {props.prevYear.substr(2, 2)}
            </div>
          ) : null}

          <div
            className="current-year"
            css={css`
              left: 0;
            `}
          >
            {props.currentYear?.substr(2, 2)}
          </div>

          {props.nextYear ? (
            <div
              className="next-year"
              css={css`
                position: absolute;
                transform: translate3d(100%, 0, 0);
                opacity: 0;
              `}
            >
              {props.nextYear.substr(2, 2)}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

Time.propTypes = {
  prevYear: PropTypes?.string,
  currentYear: PropTypes?.string,
  nextYear: PropTypes?.string,
  transition: PropTypes?.bool,
  middle: PropTypes?.bool,
  direction: PropTypes?.string,
}

export default Time
