/** @jsx jsx */
import { jsx } from "@emotion/core"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import { Col, Container, Row } from "react-bootstrap"
import Section from "../components/Section"
import Timeline from "../components/Timeline"
import { ParallaxBanner } from "react-scroll-parallax"
import { MapAndCaroussel } from "../components/Map-Caroussel"
const SecondPage = () => {
  return (
    <Layout footerBg={"#fff"}>
      <SEO
        title="Qui sommes nous ?"
        description="Acteur incontournable du B2B, SimulAssur est courtier grossiste indépendant en assurance de personne depuis plus de 15 ans"
      />
      <ParallaxBanner
        layers={[
          {
            image: require("../images/others/qui-sommes-nous.png"),
            amount: 0.1,
          },
        ]}
        style={{ height: "auto" }}
      >
        <Section
          className="d-flex align-items-center"
          css={{
            background: `linear-gradient(180deg, rgba(244,246,254, 1) 50%, rgba(255,255,255, 1) 100%)`,
          }}
        >
          <Container css={{ marginTop: "60px" }}>
            <Row>
              <Col lg={6}>
                <h1>
                  <span className="text-secondary">Le partenaire n°1</span> des
                  professionnels
                </h1>
                <p className="my-5">
                  Acteur incontournable du B2B, SimulAssur est courtier
                  grossiste indépendant en assurance de personne depuis plus de
                  15 ans{" "}
                </p>
                <Link
                  className="btn btn-secondary btn-width"
                  to="/inscription/"
                >
                  Devenir partenaire
                </Link>
              </Col>
            </Row>
          </Container>
        </Section>
      </ParallaxBanner>
      <Section>
        <Container>
          <Row>
            <Col>
              <h2 className="mt-3 text-center mb-2 mb-md-5">
                Des commerciaux dédiés
                <br />
                <span className="font-weight-light">dans toute la France</span>
              </h2>
            </Col>
          </Row>

          <MapAndCaroussel />

          <Row>
            <Col>
              <p className="text-center mt-5 mb-0">
                <span className="font-weight-semi-bold">
                  Vous êtes un client particulier ?
                </span><br/>
                Contactez directement votre courtier pour toute question.
              </p>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section css={theme => ({ background: theme.gradients.primary })}>
        <Container>
          <Row className="text-center text-white">
            <Col md="4">
              <h3 className="display-4 font-weight-bold mb-0">
                200{"\u00A0"}k
              </h3>
              <p className="mb-0">
                Assurés sont passés
                <br />
                par nos services
              </p>
            </Col>
            <Col md="4" className="my-4 my-md-0">
              <h3 className="display-4 font-weight-bold mb-0">
                3{"\u00A0"}000
              </h3>
              <p className="mb-0">
                Professionnels
                <br />
                nous font confiance
              </p>
            </Col>
            <Col md="4">
              <h3 className="display-4 font-weight-bold mb-0">50</h3>
              <p className="mb-0">
                Experts pour
                <br />
                vous accompagner
              </p>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="bg-white">
        <h2 className="mt-3 text-center">
          Réactivité, simplicité, innovation
          <br /> <span className="font-weight-light">au cœur de notre ADN</span>
        </h2>
      </Section>
    </Layout>
  )
}

export default SecondPage
