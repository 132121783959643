import React from "react"
import "./Card.css" // Import the CSS file with your custom styles
export const Card = ({
  region,
  jobTitle,
  lastName,
  firstName,
  email,
  phone,
  linkedin,
  image,
  isSelected = false,
}) => {
  const formatPhoneNumber = phoneNumber => {
    return phoneNumber.replace(
      /^(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/,
      "$1 $2 $3 $4 $5"
    )
  }
  return (
    <div className="card" style={{ cursor: "pointer" }}>
      <div
        className="card-image"
        style={{
          backgroundImage: `url('${image}')`,
          height: isSelected ? "16rem" : "8rem",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>
      <div className="card-content">
        <p className="card-firstname">{firstName}</p>
        <p className="card-lastname">{lastName}</p>

        {region ? (
          <>
            <p className="card-region">Région</p>
            <p className="card-region-title">{region}</p>
          </>
        ) : (
          <>
            <p className="card-region">&nbsp;</p>
            <p className="card-region-title">France</p>
          </>
        )}

        {jobTitle ? (
            <p className="card-region-title">{jobTitle}</p>
        ) : (
            <p className="card-region-title">&nbsp;</p>
        )}

        <div className="card-info">
          <p className="card-email">
            <a href={`mailto:${email}`} aria-label="email">
              <img
                src={require("../../images/icons/email.svg")}
                alt="email icon"
              />
            </a>
          </p>
          <p className="card-phone">
            <a href={`tel:${phone}`}>
              <img
                src={require("../../images/icons/phone.svg")}
                alt="phone icon"
              />
              <span className="pl-1">{formatPhoneNumber(phone)}</span>
            </a>
          </p>
          <p className="card-linkedin">
            {linkedin && (
              <a
                href={linkedin}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="linkedin url"
              >
                <img
                  src={require("../../images/icons/darkLinkedin.svg")}
                  alt="linkedin icon"
                />
              </a>
            )}
          </p>
        </div>
      </div>
    </div>
  )
}
