import React, { useEffect, useRef } from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { Card } from "./Card"
import { REGION_REPRESENTATIVES } from "./utils"

export const CustomCarousel = ({ regionId }) => {
  const carouselRef = useRef()
  const carouselWrapper = useRef()

  return (
    <div ref={carouselWrapper}>
      <Carousel
        ref={carouselRef}
        arrows={false}
        slidesToSlide={1}
        containerClass=""
        itemClass="card-item"
        dotListClass=""
        sliderClass=""
        centerMode
        responsive={{
          desktop_xxl: {
            breakpoint: {
              max: 10000,
              min: 1400,
            },
            items: 5,
            partialVisibilityGutter: 0,
          },
          desktop_xl: {
            breakpoint: {
              max: 1400,
              min: 1200,
            },
            items: 3,
            partialVisibilityGutter: 0,
          },
          desktop_lg: {
            breakpoint: {
              max: 1200,
              min: 992,
            },
            items: 3,
            partialVisibilityGutter: 0,
          },
          desktop_md: {
            breakpoint: {
              max: 992,
              min: 768,
            },
            items: 1,
            partialVisibilityGutter: 0,
          },
          desktop_sm: {
            breakpoint: {
              max: 768,
              min: 576,
            },
            items: 1,
            partialVisibilityGutter: 0,
          },
          desktop_xs: {
            breakpoint: {
              max: 576,
              min: 0,
            },
            items: 1,
            partialVisibilityGutter: 0,
          },
        }}
        focusOnSelect
        infinite
        renderDotsOutside
        showDots
      >
        {REGION_REPRESENTATIVES.map((person, index) => (
          <Card
            data-index={index}
            key={index}
            region={person.region}
            lastName={person.lastName}
            firstName={person.firstName}
            email={person.email}
            phone={person.phone}
            jobTitle={person.jobTitle}
            linkedin={person.linkedin}
            image={person.image}
            isSelected={regionId === person.region?.toLocaleLowerCase()}
          />
        ))}
      </Carousel>
    </div>
  )
}
