export const REGION_REPRESENTATIVES = [
    {
      image: require('../../images/qui-sommes-nous/GUB.png'),
      region: "Sud Ouest",
      regionId: 'sdo',
      lastName: "Bord",
      firstName: "Guillaume",
      email: "guillaume.bord@simulassur.fr",
      phone: "0660786821",
      // jobTitle: "Animateur réseau",
      linkedin: "https://www.linkedin.com/in/guillaume-bord-191196139",
    },
    {
      image: require('../../images/qui-sommes-nous/FDL.png'),
      region: "Grand Nord",
      regionId: 'gdn',
      lastName: "Lorton",
      firstName: "Frédéric",
      email: "frederic.lorton@simulassur.fr",
      phone: "0660541188",
      // jobTitle: "Délégué Régional",
      linkedin: "https://www.linkedin.com/in/fr%C3%A9d%C3%A9ric-lorton-0540b7201/",
    },
    {
      image: require('../../images/qui-sommes-nous/AYC.png'),
      region: "RHÔNE ALPES - AUVERGNE",
      regionId: 'rha',
      lastName: "Aymard",
      firstName: "Clarisse",
      email: "clarisse.aymard@simulassur.fr",
      phone: "0659790801",
      // jobTitle: "Directrice Régionale",
      linkedin: "https://www.linkedin.com/in/clarisseaymard/",
    },
    {
      image: require('../../images/qui-sommes-nous/JRN.png'),
      region: "Grand Ouest",
      regionId: 'gdo',
      lastName: "Robin",
      firstName: "Jasmine",
      email: "jasmine.robin@simulassur.fr",
      phone: "0659641280",
      linkedin: "https://fr.linkedin.com/in/jasmine-robin-955663119"
    },
    {
      image: require('../../images/qui-sommes-nous/NDD.png'),
      region: "IDF Ouest",
      regionId: 'ido',
      lastName: "Ndao",
      firstName: "Dicko",
      email: "dicko.ndao@simulassur.fr",
      phone: "0660812260",
      // jobTitle: "Inspectrice commerciale emprunteur",
      linkedin: "https://www.linkedin.com/in/dicko-n-dao-40157976/",
    },
    {
      image: require('../../images/qui-sommes-nous/ALLR.png'),
      region: "Sud Est",
      regionId: 'sde',
      lastName: "Alleno",
      firstName: "Romain",
      email: "romain.alleno@simulassur.fr",
      phone: "0659942580",
      // jobTitle: "Directeur Régional",
      linkedin: "https://www.linkedin.com/in/romain-alleno-8b4107b1/",
    },
    {
      image: require('../../images/qui-sommes-nous/JML.png'),
      region: "Sud Centre",
      regionId: 'sdc',
      lastName: "Lamour",
      firstName: "Jeremy",
      email: "jeremy.lamour@simulassur.fr",
      phone: "0660553705",
      // jobTitle: "Animateur réseau",
      linkedin: "https://www.linkedin.com/in/jeremylamour/",
    },
    {
      image: require('../../images/qui-sommes-nous/PTF.png'),
      region: "Normandie",
      regionId: 'nmd',
      lastName: "Fatras",
      firstName: "Patricia",
      email: "patricia.fatras@simulassur.fr",
      phone: "0665002840",
      // jobTitle: "Directrice Régionale",
      linkedin: "https://www.linkedin.com/in/patricia-fatras-895a16a4/",
    },
    {
      image: require('../../images/qui-sommes-nous/DLE.png'),
      region: "Paris Est",
      regionId: 'ide',
      lastName: "Delesque",
      firstName: "Edouard",
      email: "edouard.delesque@simulassur.fr",
      phone: "0661496192",
      // jobTitle: "Directeur Régional",
      linkedin: "https://www.linkedin.com/in/edouarddelesque/",
    },
    {
      image: require('../../images/qui-sommes-nous/SBB.png'),
      region: "Grand Est",
      regionId: 'gde',
      lastName: "Bauchard",
      firstName: "Sabrina",
      email: "sabrina.bauchard@simulassur.fr",
      phone: "0664581812",
      // jobTitle: "Inspectrice commerciale emprunteur",
      linkedin: "https://www.linkedin.com/in/sabrina-bauchard-50433488/",
    },
    {
      image: require('../../images/qui-sommes-nous/PRP.png'),
      region: "",
      lastName: "Relation Partenaires",
      firstName: "Pôle",
      regionId: 'centre',
      email: "pole.relationscourtiers@simulassur.fr",
      phone: "0252070181",
      // jobTitle: "Pôle Relation Partenaires",
      linkedin: "",
    },
    {
      image: require('../../images/qui-sommes-nous/BJK.png'),
      region: "",
      lastName: "Kaulanjan",
      firstName: "Benjamin",
      email: "benjamin.kaulanjan@simulassur.fr",
      phone: "0785199934",
      jobTitle: "Directeur Commercial",
      linkedin: "https://www.linkedin.com/in/benjamin-kaulanjan/",
    },
  ];
  