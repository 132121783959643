import React, { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { CustomCarousel } from "./Carousel/Carousel"

export const MapAndCaroussel = () => {
  const [regionId, setRegionId] = useState(null)

  const handleClick = regionId => {
    setRegionId(regionId);
  };

  return (
    <Row>
      <Col>
        <CustomCarousel regionId={regionId} />
      </Col>
    </Row>
  )
}
