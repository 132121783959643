/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import Time from "./Time"
import React from "react"
import styled from "@emotion/styled"

const ButtonNav = styled.button`
  position: absolute;
  border-radius: 50%;
  border: 2px solid #cfe6f1;
  padding: 0;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  top: -110px;
  @media (min-width: 768px) {
    top: 0;
  }
  &:focus {
    outline: none;
  }
`

class Timeline extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      timeActive: [0, 1, 2],
      transition: false,
      direction: "next",
      finish: true,
    }
    this.handleClick = this.handleClick.bind(this)
  }

  async handleClick(direction) {
    if (this.state.transition === false) {
      let newValues
      this.setState({ transition: true, direction: direction })
      await setTimeout(() => {
        if (direction === "next") {
          newValues = this.state.timeActive.map(value => value + 1)
        } else {
          newValues = this.state.timeActive.map(value => value - 1)
        }
        this.setState({ timeActive: newValues, transition: false })
      }, 350)
    }
  }

  render() {
    const { timeActive, transition, direction } = this.state
    return (
      <div
        className="wrapper-timeline mb-3"
        css={css`
          position: relative;
          z-index: 1;
        `}
      >
        <div
          className="timeline container"
          css={css`
            display: flex;
            &:before {
              content: "";
              display: block;
              position: absolute;
              top: 100px;
              left: 0;
              right: 0;
              border-top: 3px solid #a1a6ac;
            }
          `}
        >
          {timeline
            .filter(item => timeActive.indexOf(item.id) !== -1)
            .map((time, idx) => {
              const prevYear = timeline[timeActive[idx] - 1]
                ? timeline[timeActive[idx] - 1].year
                : null
              const currentYear = time.year
              const nextYear = timeline[timeActive[idx] + 1]
                ? timeline[timeActive[idx] + 1].year
                : null
              return (
                <Time
                  key={time.id}
                  prevYear={prevYear}
                  currentYear={currentYear}
                  nextYear={nextYear}
                  transition={transition}
                  middle={idx === 1}
                  direction={direction}
                />
              )
            })}
        </div>
        <div
          className={`wrapper-months container text-center`}
          css={css`
            position: relative;
          `}
        >
          {timeline[timeActive[0] - 1] ? (
            <ButtonNav
              css={css`
                left: 15px;
                @media (min-width: 768px) {
                  transform: translateX(-50%);
                  left: 50%;
                  margin-left: -220px;
                }
              `}
              onClick={() => this.handleClick("prev")}
            >
              {"<"}
            </ButtonNav>
          ) : null}

          <div
            className={`months ${!transition ? `active` : ""}`}
            css={css`
              transition: all 0.33s ease-out;
              opacity: 0;
              position: relative;
              max-width: 310px;
              margin: 50px auto 0;
              color: #a1a6ac;
              box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
              padding: 50px;
              border-radius: 25px;
              &:after {
                content: "";
                display: block;
                position: absolute;
                top: -8px;
                left: 50%;
                width: 18px;
                height: 18px;
                border-radius: 3px;
                background-color: #fff;
                transform: translateX(-50%) rotate(-45deg);
                z-index: 1;
              }
            `}
          >
            {timeline[timeActive[1]].months.map(month => (
              <div key={month.name} className="month">
                <h6
                  className="month-name"
                  css={theme => css`
                    color: ${theme.colors.primary};
                  `}
                >
                  {month.name}
                </h6>
                <div className="month-content">
                  <p
                    className="mb-0"
                    css={css`
                      font-size: 16px;
                    `}
                  >
                    {month.description}
                  </p>
                </div>
              </div>
            ))}
          </div>

          {timeline[timeActive[2]] ? (
            <ButtonNav
              css={css`
                right: 15px;
                @media (min-width: 768px) {
                  transform: translateX(-50%);
                  right: 50%;
                  margin-right: -260px;
                }
              `}
              onClick={() => this.handleClick("next")}
            >
              {">"}
            </ButtonNav>
          ) : null}
        </div>
      </div>
    )
  }
}

const timeline = [
  {
    id: 0,
    year: null,
  },
  {
    id: 1,
    year: "2011",
    months: [
      {
        name: "Mars",
        description:
          "Magnolia.fr courtier 100% Digital N°1 en Assurance de Prêt sur internet crée Simulassur le premier comparateur gratuit dédié́ aux professionnels du courtage.",
      },
    ],
  },
  {
    id: 2,
    year: "2015",
    months: [
      {
        name: "Février",
        description:
          "Simulassur accompagne la croissance de plus de 1000 partenaires via sa plateforme ultra simple et son espace de souscription Full Online.",
      },
    ],
  },
  {
    id: 3,
    year: "2018",
    months: [
      {
        name: "Janvier",
        description:
          "Magnolia.fr fait l’acquisition d’Élois Assurance et devient le premier réseau de distribution indépendant en Assurance Emprunteur individuelle.",
      },
    ],
  },
  {
    id: 4,
    year: "2020",
    months: [
      {
        name: "Mars",
        description:
          "Simulassur simplifie les reprises Hamon /Bourquin et accompagne les courtiers dans la résiliation.",
      },
    ],
  },
  {
    id: 5,
    year: "2021",
    months: [
      {
        name: "Janvier",
        description: "Upcoming News … !",
      },
    ],
  },
]

export default Timeline
